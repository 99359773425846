import bundle from '@/utilities/bundle';

export const REP_ROOT_PATH = '/rep/:invitationId';

export const REP_START_ROUTE = {
    path: '/',
    name: 'rep',
    component: bundle.loadView('rep/start/start'),
    props: true
};

export const REP_SUCCESS_ROUTE = {
    path: '/success',
    name: 'rep-success',
    component: bundle.loadView('rep/success/success'),
    props: true
};

export const REP_FAIL_ROUTE = {
    path: '/fail/:eventId?',
    name: 'rep-fail',
    component: bundle.loadView('rep/fail/fail'),
    props: true
};

export const REP_SESSION_STATUS_ROUTE = {
    path: '/session-status',
    name: 'rep-session-status',
    component: bundle.loadView('rep/session-status/session-status'),
    props: true
};

export const REP_BASE_ROUTES = {
    path: REP_ROOT_PATH,
    children: [
        REP_START_ROUTE,
        REP_SUCCESS_ROUTE,
        REP_FAIL_ROUTE,
        REP_SESSION_STATUS_ROUTE
    ]
};
